import { navigate } from "gatsby";
import React from "react";
import { Price } from "../../utilities/price";
import CartAddToCart from "./cart-add-to-cart";

const RecommendedProductBox = ({ product, addToCartNotify, discount = 0 }) => {
  let price = +product.node.product.salePrice
    ? +product.node.product.salePrice
    : +product.node.product.basePrice;

  if (discount) price = price - discount;

  return (
    <div
      className="py-4 md:p-4 w-full md:w-1/3 lg:w-1/4"
      key={product.node.product.sku}
    >
      <div className="flex flex-col justify-between max-w-sm h-full rounded overflow-hidden shadow-lg">
        <div
          className="h-64 w-full cursor-pointer"
          style={{
            backgroundImage: `url(${product.node.featuredImage.node.localFile.publicURL})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => navigate(`/${product.node.slug}/`)}
          onKeyDown={() => navigate(`/${product.node.slug}/`)}
          role="button"
          aria-label="View product"
          tabIndex="0"
        ></div>
        <div className="px-6 py-4">
          <div
            className="font-bold text-xl mb-2"
            dangerouslySetInnerHTML={{ __html: product.node.title }}
          />
          <p className="text-grey-darker text-base">
            {product.node.product.cartCalloutText}
          </p>
        </div>
        <div className="px-6 md:py-4">
          <p className="mt-3">
            {price !== +product.node.product.basePrice && (
              <span className="line-through text-lg pr-2">
                <Price value={product.node.product.basePrice} />
              </span>
            )}
            <span className="text-xl">
              <Price value={price} />
            </span>
          </p>
        </div>
        <div className="px-6 pb-4 md:pt-4">
          <div className="flex mt-2 md:mt-6 items-center justify-between w-full">
            <CartAddToCart
              key={product.node.id}
              product={product}
              buttonClasses={`bg-${process.env.THEME_COLOR_PRIMARY} shadow appearance-none border border-grey-light rounded ml-2 py-2 px-3 text-${process.env.THEME_COLOR_PRIMARY}-lightest leading-tight focus:outline-none focus:ring h-10 uppercase`}
              notify={addToCartNotify}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RecommendedProductBox;
