import classNames from "classnames";
import { Link } from "gatsby";
import groupBy from "just-group-by";
import pluralize from "pluralize";
import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import CartBundleDetail from "../components/partials/cart-bundle-detail";
import CartItemDetail from "../components/partials/cart-item-detail";
import CartTotals from "../components/partials/cart-totals";
import RecommendedProductBox from "../components/partials/recommended-product-box";
import Seo from "../components/seo";
import CartContext from "../context/cart-context";
import useRushOrderProduct from "../hooks/useRushOrderProduct";
import { styles } from "../utilities/styles";

function CartPage() {
  const cartContext = useContext(CartContext);
  const bundleProds = cartContext.contents.filter(
    (product) => product.bundleId
  );
  const bundles = groupBy(bundleProds, (product) => product.bundleId);
  const recommendedProducts = cartContext.recommendedProducts()
    ? cartContext.recommendedProducts()
    : [];

  function addToCartNotify(text) {
    toast.success(text);
  }

  useEffect(() => {}, []);

  const rushOrderProduct = useRushOrderProduct();

  return (
    <>
      <Seo title="Cart" />
      <div className="container mx-auto mb-16 px-4 md:px-8">
        <div className="bg-white w-full lg:w-auto pt-0 md:p-0">
          <div className="p-4 md:p-0 pb-3 pr-2 uppercase flex justify-between h-16 items-end border-b border-grey-light">
            <h3 className="text-xl font-semibold text-grey-darkest">
              Shopping Cart
            </h3>
            <p>
              {cartContext.totals.countItems}{" "}
              {pluralize("Item", cartContext.totals.countItems)}
            </p>
          </div>
          <div className="grid md:grid-cols-5 mt-8 gap-6">
            <div className="mt-4 grid items-start gap-4 md:col-span-3">
              {Object.entries(bundles).map(([key, bundle]) => (
                <CartBundleDetail bundleId={key} bundle={bundle} key={key} />
              ))}
              {cartContext.contents.map((product, index) => {
                if (product.bundleId) return null;

                return <CartItemDetail key={product.id} product={product} />;
              })}
              {cartContext.contents.length === 0 && (
                <div className="p-8">
                  <h3>Your cart is empty</h3>
                  <Link
                    to="/products/"
                    className={classNames(
                      "mt-8 inline-block tracking-wide px-8 py-2 no-underline rounded",
                      styles.button.default
                    )}
                  >
                    View all products
                  </Link>
                </div>
              )}
            </div>
            {cartContext.contents.length !== 0 && (
              <div className="py-4 w-full md:col-span-2">
                <CartTotals />
                <div className="flex justify-end py-4 px-4 md:px-0 uppercase">
                  <Link
                    to="/checkout/"
                    className={classNames(
                      "block bg-${process.env.THEME_COLOR_PRIMARY} text-black text-sm px-4 py-3 no-underline rounded",
                      styles.button.default
                    )}
                  >
                    Checkout
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className={`${
            cartContext.totals.countItems && recommendedProducts.length
              ? "flex flex-wrap"
              : "hidden"
          } mt-8`}
        >
          <div className="pb-4 w-full text-white">
            <h3 className="mb-4 px-4 md:p-0 text-xl font-semibold text-grey-darkest uppercase">
              We also recommend
            </h3>
            <div className="pb-4 w-full text-grey-darkest">
              <div className="flex flex-wrap">
                {recommendedProducts.map((product) => {
                  return (
                    <RecommendedProductBox
                      key={product.node.id}
                      product={product}
                      addToCartNotify={addToCartNotify}
                    />
                  );
                })}
                {rushOrderProduct && (
                  <RecommendedProductBox
                    key={rushOrderProduct.node.id}
                    product={rushOrderProduct}
                    addToCartNotify={addToCartNotify}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartPage;
