import React, { useContext } from "react";
import CartContext from "../../context/cart-context";
import { Price } from "../../utilities/price";

function CartTotals() {
  const { discounts, totals } = useContext(CartContext);

  return (
    <div id="cartTotals" className="w-full py-4 px-8 leading-loose">
      <div className="flex justify-between py-2">
        <div>
          <p>Subtotal</p>
        </div>
        <div>
          <p>
            <span>
              <Price value={(totals.subtotal || "").replace("$", "")} />
            </span>
          </p>
        </div>
      </div>

      <div
        className={`${
          totals.extendedArea === "$0.00" ? "hidden" : "flex"
        } justify-between py-2`}
      >
        <div>
          <p>Extended Area Fee</p>
        </div>
        <div>
          <p>
            <span>
              <Price value={(totals.extendedArea || "").replace("$", "")} />
            </span>
          </p>
        </div>
      </div>

      <div
        className={`${
          totals.discount === "$0.00" ? "hidden" : "flex"
        } justify-between py-2 text-${process.env.THEME_COLOR_PRIMARY}`}
      >
        <div>
          <p>Discount{discounts?.name ? `: ${discounts?.name}` : ""}</p>
        </div>
        <div>
          <p className="whitespace-nowrap">
            -<Price value={(totals.discount || "").replace("$", "")} />
          </p>
        </div>
      </div>

      <div className="flex justify-between py-2">
        <div>
          <p>Tax</p>
        </div>
        <div>
          <p>
            <span>
              <Price value={(totals.tax || "").replace("$", "")} />
            </span>
          </p>
        </div>
      </div>

      <hr className="border-solid border-t border-grey-light" />

      <div className="flex justify-between py-2">
        <div>
          <div>Total</div>
        </div>
        <div>
          <p>
            <span>
              <Price value={(totals.total || "").replace("$", "")} />
            </span>
          </p>
        </div>
      </div>
      {totals.dueNow !== "$0.00" && (
        <div className="flex justify-between py-2">
          <div>
            <span>1. </span>
            <strong>Due Today</strong>
          </div>
          <div>
            <p>
              <strong>
                <Price value={(totals.dueNow || "").replace("$", "")} />
              </strong>
            </p>
          </div>
        </div>
      )}
      {totals.dueAtMeasure !== "$0.00" && (
        <div className="flex justify-between py-2">
          <div>
            <span>2. </span>Due after measurement
          </div>
          <div>
            <p>
              <span>
                <Price value={(totals.dueAtMeasure || "").replace("$", "")} />
              </span>
            </p>
          </div>
        </div>
      )}
      {totals.dueAtInstall !== "$0.00" && (
        <div className="flex justify-between py-2">
          <div>
            <span>{totals.dueAtMeasure !== "$0.00" ? "3" : "2"}. </span>
            Due after installation
          </div>
          <div>
            <p>
              <span>
                <Price value={(totals.dueAtInstall || "").replace("$", "")} />
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
export default CartTotals;
